<template>
  <v-menu
    open-on-hover
    offset-y
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" class="menu-wrapper">
        <slot name="menu-activator"></slot>
      </div>
    </template>

    <div class="menu">
      <slot name="menu"></slot>
    </div>
  </v-menu>
</template>

<script>
export default {
  name: 'RccOpenOnHoverMenu'
}
</script>

<style lang="scss" scoped>
.menu {
  background: #ffffff;
  padding: 5px;
}
</style>
