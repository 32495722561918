import constants from '@/utils/constants'

const ROLES = constants.users
const x5NetworkId = constants.networkIds.x5

export default [
  {
    width: '40px',
    value: 'edit',
    align: 'center',
    colModifiers:[
      { type: 'edit' }
    ],
    editPath: 'return-points/edit/',
    access: Object.values(ROLES)
  },
  { text: 'Сеть', value: 'network', access: Object.values(ROLES) },
  { text: 'Номер', value: 'number', access: Object.values(ROLES), showOnNetworks: [x5NetworkId] },
  {
    text: 'Название',
    value: 'name',
    customFilter: {
      type: 'multiple-autocomplete',
      label: 'Поиск по точке возврата'
    },
    access: Object.values(ROLES)
  },
  {
    text: 'Мин. кол-во',
    value: 'intermediate_booking_min_pallet',
    access: Object.values(ROLES),
    showOnNetworks: [x5NetworkId]
  },
  {
    text: 'Опт. кол-во',
    value: 'consolidation_booking_soft_limit_pallet',
    access: Object.values(ROLES),
    showOnNetworks: [x5NetworkId]
  },
  {
    text: 'Макс. кол-во',
    value: 'intermediate_booking_max_pallet',
    access: Object.values(ROLES),
    showOnNetworks: [x5NetworkId]
  },
  {
    text: 'Примечание',
    value: 'note',
    width: '100px',
    access: [ROLES.admin, ROLES.manager, ROLES.user]
  },
  {
    text: 'Регион',
    value: 'region',
    access: Object.values(ROLES),
    showOnNetworks: [x5NetworkId],
    customFilter: {
      type: 'multiple-autocomplete',
      label: 'Поиск по региону'
    }
  },
  { text: 'Юр. лицо', value: 'legal_entity', access: Object.values(ROLES) },
  { text: 'Адрес', value: 'address', access: [ROLES.admin, ROLES.manager, ROLES.user] },
  { text: 'Время подачи', value: 'delivery', width: '110px', access: Object.values(ROLES) },
  { text: 'Контакты', value: 'contacts', access: [ROLES.admin, ROLES.manager, ROLES.user] }
]
