<template>
  <div class="rcc-table-list">
    <div class="rcc-table-list__button-wrapper" v-if="editAccess">
      <rcc-button @click="$router.push({ name: 'return-points-new' })">
        Добавить точку возврата
      </rcc-button>
    </div>

    <rcc-tabs-panel
      v-if="tradingNetworks.length"
      v-model="activeTabIndex"
      :tabs="tradingNetworks"
      class="tabs"
    />

    <rcc-tab-view
      :tabs="tradingNetworks"
      :active-tab-index="activeTabIndex"
    >
      <template #default="{ tab }">
        <rcc-return-point-view
          :trading-network-id="tab.id"
          :selected-return-points="selectedReturnPoints"
          @selected-points-change="handleSelectedPointsChange"
        />
      </template>
    </rcc-tab-view>
  </div>
</template>

<script>
import Page from '@/mixins/page'

import RccButton from 'Components/controls/rcc-button'
import RccReturnPointView from './return-point-view.vue'
import RccTabsPanel from 'Components/ui/tabs/tabs-panel'
import RccTabView from 'Components/ui/tabs/tab-view'

export default {
  mixins: [Page],

  components: {
    RccReturnPointView,
    RccButton,
    RccTabsPanel,
    RccTabView
  },

  props: {
    selectedReturnPoints: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      tradingNetworks: [],
      activeTabIndex: ''
    }
  },

  computed: {
    editAccess() {
      return this.$route.meta.editAccess.includes(this.$store.user.role)
    }
  },

  mounted() {
    this.getTradingNetworksList()
  },

  methods: {
    getTradingNetworksList() {
      return this.$apiMethods.tradingNetworks.allList()
        .then(({ items }) => {
          this.tradingNetworks = items

          if (this.tradingNetworks.length) {
            const tradingNetworkId = this.$route.params.tradingNetworkId

            this.activeTabIndex = tradingNetworkId ?
              this.tradingNetworks.findIndex(({ id }) => id === tradingNetworkId) :
              0
          }
        })
    },

    handleSelectedPointsChange(points) {
      this.$emit('selected-points-change', points)
    }
  }
}
</script>

<style lang="scss">
.rcc-table-list {
  display: flex;
  flex-direction: column;

  .tabs {
    margin-bottom: 10px;
  }

  .tab-view {
    flex-grow: 1;

    .v-window, .v-window-item {
      height: 100%;
    }
  }
}
</style>
