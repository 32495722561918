<template>
  <div class="tab-view">
    <v-tabs-items :value="activeTabIndex">
      <v-tab-item
        v-for="tab in tabs"
        :key="tab.id"
      >
        <slot v-bind:tab="tab" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  name: 'RccTabView',

  props: {
    tabs: {
      type: Array,
      default: () => []
    },

    activeTabIndex: {
      type: [String, Number],
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-view {
  .v-window {
    background: initial;
  }
}
</style>
