<template>
  <div class="tabs-panel">
    <v-tabs
      :value="value"
      background-color="transparent"
      @change="handleTabChange"
    >
      <v-tab
        v-for="({ id, name }) in tabs"
        :key="id"
        class="tab"
      >
        {{ name }}
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
export default {
  name: 'RccTabsPanel',

  props: {
    tabs: {
      type: Array,
      default: () => []
    },

    value: {
      type: [String, Number],
      default: null
    }
  },

  methods: {
    handleTabChange(index) {
      this.$emit('input', index)
    }
  }
}
</script>

<style lang="scss">
.tabs-panel {
  .tab {
    border-radius: 8px 8px 0 0;

    &::before {
      border-radius: 8px 8px 0 0;
    }

    &.v-tab--active {
      // font-weight: 800;
      background: rgba($primary, 0.1);
    }
  }
}
</style>
